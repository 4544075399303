export default {
  computed: {
    masks() {
      return {
        phone: ["(##) #####-####", "(##) ####-####"],
        cpfOrCnpj: ["###.###.###-##", "##.###.###/####-##"],
        cpf: "###.###.###-##",
        cnpj: "##.###.###/####-##",
        cnh: "###########",
        birthday: "##/##/####",
        cep: "#####-###",
        rg: "###########",
        zipCode: "#####-###"
      };
    }
  }
};
